// switcherr
.theme-switcher-label {
    cursor: pointer;
    position: fixed;
    left: 135px;
    top: 6px;
    z-index: 99;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 1200px) and (max-width: 1500px) {
        left: 220px;
        top: 35px;
    }

    @media (max-width: 1199px) {
        width: 45px;
        height: 45px;
        left: auto;
        right: 80px;
        bottom: auto;
        top: 13px;
        z-index: 99;
    }

    .switch-handle {
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;

        i {
            position: absolute;
            width: 22px;
            height: 22px;
            transition: 0.3s;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .theme-switcher {
        display: none;
    }
    .light-text {
        color: #000;
    }
    .dark-text {
        color: #fff;
    }
    &.active {
        background-color: rgba(255, 255, 255, 0.2);
        .light-text {
            visibility: hidden;
            opacity: 0;
        }
        .dark-text {
            visibility: visible;
            opacity: 1;
        }
    }
    .dark-text {
        visibility: hidden;
        opacity: 0;
    }
    input {
        cursor: pointer;
    }
}
